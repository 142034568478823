import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import gsap from "gsap";
import parse from "html-react-parser";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import Layout from "../components/layout";

const AboutUsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
				link
			}
			img1: wpMediaItem(title: { eq: "What-We-Do-Image" }) {
				altText
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
				link
			}
			wpSeoPage(title: { eq: "About Us" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
						altText
						mediaDetails {
							height
							width
						}
						link
					}
					opengraphTitle
					opengraphDescription
				}
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wpAboutUsPage {
				aboutPageFields {
					heroSection {
						description
						title
						button1 {
							link
							text
						}
					}
					ourValuesSection {
						title
						description
						value1
						value2
						value3
						value4
						value5
					}
					whoWeAreSection {
						description
						title
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						item1 {
							title
							description
						}
						item2 {
							title
							description
						}
						item3 {
							title
							description
						}
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		wpAboutUsPage: {
			aboutPageFields: { heroSection, ourValuesSection, whoWeAreSection },
		},
	} = data;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
					id: `${siteUrl}/about-us`,
				},
			},
		],
	};
	const enterAnimation = (text) => {
		gsap.to(
			text,

			{ color: "#FFCA2C", duration: 0.5 }
		);
	};
	const exitAnimation = (text) => {
		gsap.to(
			text,

			{ color: "#F5F5F5", duration: 0.5 }
		);
	};
	const enterAnimationBlue = (text) => {
		gsap.to(
			text,

			{ color: "#40B4E5", duration: 0.5 }
		);
	};
	const exitAnimationBlue = (text) => {
		gsap.to(
			text,

			{ color: "#F5F5F5", duration: 0.5 }
		);
	};
	const enterAnimationDarkBlue = (text) => {
		gsap.to(
			text,

			{ color: "#023A7F", duration: 0.5 }
		);
	};
	const exitAnimationDarkBlue = (text) => {
		gsap.to(
			text,

			{ color: "#F5F5F5", duration: 0.5 }
		);
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						customTitle
						button1="primary-link-dark"
						button1Text={heroSection.button1.text}
						button1Link="/contact-us"
						button1Var="white"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={heroSection.title}
						description={heroSection.description}
					/>
				</section>
				<section className="bg-light-grey py-5 py-lg-10">
					<Container>
						<Row className="align-items-center justify-content-between ">
							<Col lg={5} className=" ">
								<div>{parse(whoWeAreSection.title)}</div>

								<div>{parse(whoWeAreSection.description)}</div>
								<div style={{ overflow: "hidden", borderRadius: "15px" }}>
									<GatsbyImage
										className="w-100"
										image={whoWeAreSection.image.gatsbyImage}
										alt={whoWeAreSection.image.altText}
									/>
								</div>
							</Col>
							<Col lg={5}>
								<h3 className="fs-4 mt-5 mt-lg-0">
									{whoWeAreSection.item1.title}
								</h3>

								<p>{whoWeAreSection.item1.description}</p>
								<h3 className="mt-5 fs-4">{whoWeAreSection.item2.title}</h3>
								<p>{whoWeAreSection.item2.description}</p>
								<h3 className="mt-5 fs-4">{whoWeAreSection.item3.title}</h3>
								<p>{whoWeAreSection.item3.description}</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="bg-light-grey pb-5 pb-lg-7">
					<Container>
						<Row className="align-items-center justify-content-between ">
							<Col lg={5} className=" ">
								<div>{parse(ourValuesSection.title)}</div>
								<div>{parse(ourValuesSection.description)}</div>
							</Col>
							<Col className="d-none d-lg-block" lg={6}>
								<h2
									onMouseEnter={() => enterAnimationBlue(".grow-hover-1-blue")}
									onMouseLeave={() => exitAnimationBlue(".grow-hover-1-blue")}
									className="display-2 mb-5 grow-hover-1-blue text-center text-xl-start"
								>
									TRUST
								</h2>
								<h2
									onMouseEnter={() => enterAnimation(".grow-hover-2-yellow")}
									onMouseLeave={() => exitAnimation(".grow-hover-2-yellow")}
									className="display-2 mb-5 grow-hover-2-yellow text-center text-xl-end"
								>
									EXPERTISE
								</h2>
								<h2
									onMouseEnter={() =>
										enterAnimationDarkBlue(".grow-hover-3-dark-blue")
									}
									onMouseLeave={() =>
										exitAnimationDarkBlue(".grow-hover-3-dark-blue")
									}
									className="display-2 mb-5 grow-hover-3-dark-blue text-center text-xl-start"
								>
									TRANSPARENT
								</h2>
								<h2
									onMouseEnter={() => enterAnimation(".grow-hover-4-yellow")}
									onMouseLeave={() => exitAnimation(".grow-hover-4-yellow")}
									className="display-2 mb-5 grow-hover-4-yellow text-center text-xl-end"
								>
									INTEGRITY
								</h2>
							</Col>
							<Col className="d-lg-none text-dark-med-grey mt-5" lg={6}>
								<h2 className="display-2 mb-5 text-center">TRUST</h2>
								<h2 className="display-2 mb-5 text-center">EXPERTISE</h2>
								<h2 className="display-2 mb-5 text-center">TRANSPARENT</h2>
								<h2 className="display-2 mb-5 text-center">INTEGRITY</h2>
							</Col>
						</Row>
						<Row>
							<Col className="d-none d-lg-block">
								<h2
									onMouseEnter={() =>
										enterAnimationDarkBlue(".grow-hover-5-dark-blue")
									}
									onMouseLeave={() =>
										exitAnimationDarkBlue(".grow-hover-5-dark-blue")
									}
									className="display-1 mb-6 grow-hover-5-dark-blue text-center"
								>
									ACCOUNTABILITY
								</h2>
							</Col>
							<Col className="d-lg-none">
								<h2 className="display-1 mb-6 text-dark-med-grey text-center">
									ACCOUNTABILITY
								</h2>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section>
					<TeamSection />
				</section> */}
				{/* <Memberships /> */}
				{/* <OSSection
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1.altText}
				/> */}
			</Layout>
		</>
	);
};

export default AboutUsPage;
